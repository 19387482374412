import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../components/layout"
import { ComiteRegionalNav } from "../../components/menus/ComiteRegionalNav"

const Relance: React.FC = () => (
  <Layout>
    <Helmet title="Plan de relance 2023" />
    <div className="container">
      <div className="row mt-3">
        <div className="secondary-col">
          <ComiteRegionalNav />
        </div>
        <div className="main-col">
          <h1>Plan de relance 2023</h1>
          <p>Ci-dessous les notices pour les aides du plan de relance 2023.</p>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Document</th>
                <th scope="col">Lien</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aide petit matériel</td>
                <td>
                  <a href="/relance/Notice Aide petit matériel nov 2022.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> Notice (pdf)
                  </a>
                </td>
              </tr>
              <tr>
                <td>Aide ciblerie</td>
                <td>
                  <a href="/relance/Notice Aide ciblerie nov 2022.pdf" target="_blank" rel="noopener noreferrer" className="btn btn-info">
                    <span className="icon-download" /> Notice (pdf)
                  </a>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
)

export default Relance
